import { Fragment, useState } from "react";
import { Transition, Menu } from "@headlessui/react";
import {
  ChevronDownIcon,
  GlobeAltIcon,
  ArrowRightOnRectangleIcon,
  DocumentTextIcon,
  TruckIcon,
  Bars3Icon,
  ComputerDesktopIcon,
} from "@heroicons/react/24/outline";

import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { roles } from "utils/constant";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { ListCommanWay } from "API/commonApi";
import { useMutation } from "@tanstack/react-query";
import { postLogout } from "service/api";
import UseToast from "utils/AfToast";
import { useAuthStore } from "store/auth";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const [userDetails, setUserDetails] = useState<any>({});
  const [branchData, setBranchData] = useState<any>({});
  const [subbranchData, setSubbranchData] = useState<any>({});

  const navigator = useNavigate();
  const { setToken, setUser, token, removeAll } = useAuthStore();

  const {
    mutate,
    isLoading,
    data: data_,
  } = useMutation(
    (data: any) =>
      ListCommanWay(
        data.url,
        {
          id: data.id,
        },
        {}
      ),
    {
      onSuccess: (data: any) => {
        if (data.status == "SUCCESS") {
          setBranchData(data.data.data[0]);
        }
      },
      onError: (data: any) => {},
    }
  );

  React.useEffect(() => {
    const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
    if (Object.keys(userD).length) {
      setUserDetails(userD.state.user);
      if (userD.state.user.branch_name) {
        mutate({
          type: "branch",
          url: "/admin/branch/list",
          id: userD.state.user.branch_name,
        });
      } else if (userD.state.user.subbranch) {
        mutate({
          type: "subbranch",
          url: "/admin/subbranch/list",
          id: userD.state.user.subbranch,
        });
      }
    }
  }, []);

  const USER_PERMISSION = [];

  const navigation: any = [
    ...(userDetails.roleName === roles.SUPER_ADMIN
      ? [
          {
            name: "Admin MASTER",
            icon: Bars3Icon,
            current: false,
            children: [
              { name: "AWB Gen", to: "/awb/generate" },
              { name: "Branch", to: "/master/branch/list" },
              { name: "Sub Branch", to: "/master/subbranch/list" },
              { name: "Rate", to: "/master/rate/list" },
              { name: "Country", to: "/master/country/list" },
              { name: "Region", to: "/master/region/list" },
              { name: "State", to: "/master/state/list" },
              { name: "City", to: "/master/city/list" },
              { name: "Product", to: "/master/product/list" },
              { name: "Employee", to: "/master/agent/list" },
              { name: "Users", to: "/master/user/list" },
              { name: "Data Change", to: "/admin/data-change" },
            ],
          },
        ]
      : []),
    ...(userDetails.roleName === roles.SUPER_ADMIN ||
    userDetails.roleName === roles.BRANCH_ADMIN ||
    userDetails.roleName === roles.SUBBRANCH_ADMIN
      ? [
          {
            name: "MASTER",
            icon: Bars3Icon,
            current: false,
            children: [{ name: "Customer", to: "/master/customer/list" }],
          },
        ]
      : []),
    ...(userDetails.roleName === roles.SUPER_ADMIN ||
    userDetails.roleName === roles.BRANCH_ADMIN ||
    userDetails.roleName === roles.SUBBRANCH_ADMIN ||
    userDetails.roleName === roles.AGENT
      ? [
          {
            name: "BOOKING",
            icon: TruckIcon,
            current: false,
            children: [
              /*{ name: "AWB List", to: "/awb/list" },*/
              { name: "Topay Pickup", to: "/topay-pickup/add" },
              { name: "Book Domestic", to: "/booking/add" },
              { name: "Book International", to: "/booking/add-intl" },
              { name: "Rate Calculator", to: "/rate/calculate" },
              { name: "Intl. Rate Calculator", to: "/rate/intl-calculate" },

              /*{ name: "Add Regular Vouchure", to: "/vouchure/regular/add" },
              { name: "Regular Vouchure List", to: "/vouchure/regular/list" },
              { name: "Add Expense Vouchure", to: "/vouchure/expense/add" },*/
              { name: "Expense Vouchure List", to: "/vouchure/expense/list" },
            ],
          },
          {
            name: "OPERATION",
            icon: TruckIcon,
            current: false,
            children: [
              { name: "Mother Bag Tally", to: "/mother_bag/add" },
              /*{ name: "List Mother Bag Tally", to: "/mother_bag/list" },*/

              { name: "Out Scan Motherbag", to: "/mother_bag/outscan" },
              { name: "In Scan Motherbag", to: "/mother_bag/inscan" },

              { name: "Generate DRS", to: "/drs/manage" },
              { name: "List  DRS", to: "/drs/list" },

              { name: "Send to Thirdparty", to: "/third-party/manage" },

              { name: "Update POD", to: "/pod/update" },
              { name: "Overedge", to: "/pod/overedge" },
            ],
          },
          {
            name: "Report",
            icon: TruckIcon,
            current: false,
            children: [
              { name: "Booking", to: "/report/booking" },
              { name: "Cash Report", to: "/report/cash" },
              { name: "Attendance", to: "/report/attendance" },
              { name: "Invoice List", to: "/booking/list" },
            ],
          },
        ]
      : []),
    

    ...(userDetails.roleName === roles.ACCOUNTANT || userDetails.roleName === roles.MANAGER
      ? [
          {
            name: "Operation",
            icon: TruckIcon,
            current: false,
            children: [{ name: "Expense Vouchure List", to: "/vouchure/expense/list" }],
          },
        ]
      : []),
      ...(userDetails.roleName === roles.MANAGER
        ? [
            {
              name: "Report",
              icon: TruckIcon,
              current: false,
              children: [
                { name: "Booking", to: "/report/booking" },
                { name: "Cash Report", to: "/report/cash" },
              ],
            },
          ]
        : []),
    ...(userDetails.roleName === roles.SUPER_ADMIN || userDetails.roleName === roles.ACCOUNTANT || userDetails.roleName === roles.MANAGER
      ? [
          {
            name: "Billing",
            icon: Bars3Icon,
            current: false,
            children: [{ name: "Generate Vouchure", to: "/billing/vouchure-gen" }],
          },
        ]
      : []),

    ...(userDetails.roleName === "CUSTOMER"
      ? [
          {
            name: "Booking Report",
            icon: TruckIcon,
            current: false,
            to: "/customer-report/booking",
          },
        ]
      : []),
  ];

  const logout = () => {
    postLogout({ data: {}, headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        UseToast("Logout Successfull", "success");
        localStorage.removeItem("af_token");
        removeAll();
        navigator("/login");
      })
      .catch((err) => {
        console.error(err);
        UseToast("Login Failed! Try Again", "error");
      });
  };

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location: any = useLocation();
  const params = useParams();
  const id: string | undefined = params?.id;

  let pathName = location?.pathname;
  try {
    let id_: any = location?.state?.state;
    if (!id_) {
      id_ = location?.state?.id;
    }
    if (id_) {
      pathName = pathName.replaceAll("/edit/" + id_, "");
      pathName = pathName.replaceAll("/" + id_, "");
    }
  } catch (ignore) {}

  const sideNavigation = navigation.map((item) => {
    let isSelected: any =
      item?.children &&
      item?.children?.map((childItem) => {
        const baseNav = childItem?.to;
        if (baseNav === pathName) {
          return true;
        } else {
          return false;
        }
      });

    return (
      <div key={"main-" + item.name}>
        {!item.children ? (
          <div key={item.name}>
            <NavLink
              to={item?.to}
              onClick={() => setSidebarOpen(false)}
              key={"navlink" + item.name}
              className={(props) => {
                return classNames(
                  props?.isActive && "bg-grey_bg",
                  "group w-full flex items-center mr-2 pl-2 pr-1 py-3 font-Inter text-font_black text-left text-sm font-medium rounded-md focus:outline-none focus:bg-grey_bg active:bg-grey_bg hover:bg-grey_bg"
                );
              }}
            >
              <item.icon className={classNames(item.current ? "" : "", "mr-3 flex-shrink-0 h-6 w-6")} aria-hidden="true" />
              {item.name}
            </NavLink>
          </div>
        ) : (
          <Menu key={item.name} as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex w-full justify-center rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <GlobeAltIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                {item.name}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute z-10 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {item.children.map((subItem) => {
                  return (
                    <Menu.Item key={item.name + subItem.name}>
                      {({ active }) => (
                        <Link
                          className={`${
                            active ? "bg-dark1 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          to={subItem.to}
                        >
                          {subItem.name}
                        </Link>
                      )}
                    </Menu.Item>
                  );
                })}
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
    );
  });

  const [trackValue, setTrackValue] = useState("");
  return (
    <div key={"Asd"} className={`${pathName.includes("/drs/list") ? "print:hidden" : "print:hidden"}`}>
      {/* responsive */}
      <div className="border-b-4">
        <div className="flex flex-grow bg-gray-200">
          <div className="flex flex-shrink-0 items-center px-4">
            <Link to="/dashboard">AIRFIELD</Link>
          </div>

          <nav className="flex flex-grow items-center flex-1 space-y-1 bg-white px-2 overflow-y-visible" aria-label="Sidebar">
            {sideNavigation}
            <div>
              <Button
                onClick={() => logout()}
                className={"w-full flex items-center px-2 py-1 font-Inter text-white font-medium rounded-md bg-red-600"}
              >
                Logout
              </Button>
            </div>
            <div className="flex gap-2 ml-2 border p-1 rounded-lg">
              <div className="border border-blue-600 rounded-lg">
                <Input
                  type="text"
                  className="p-1"
                  placeholder="Track AWB"
                  value={trackValue}
                  onChange={(e) => {
                    setTrackValue(e);
                  }}
                />
              </div>
              <Button
                className="bg-blue-600 px-2 py-1 rounded-md text-white"
                onClick={() => {
                  navigator((userDetails.roleName == "CUSTOMER" ? "/customer-pod/tracking?awb=" : "/pod/tracking?awb=") + trackValue);
                  setTrackValue("");
                }}
              >
                Search
              </Button>
            </div>
          </nav>
          <div className="flex flex-col items-center justify-end px-2">
            {userDetails.name || userDetails.sh || "-"}
            <small>
              (
              {userDetails.role_name == 1
                ? "Super Admin"
                : userDetails.role_name == 2
                ? "Branch Admin"
                : userDetails.role_name == 3
                ? "Subbranch Admin"
                : userDetails.role_name == 4
                ? "Agent"
                : userDetails.role_name == 5
                ? "Accountant"
                : userDetails.role_name == 9
                ? "Manager"
                : "Customer"}
              )
            </small>
            <small>
              {branchData.name && `(${branchData.name})`}
              {branchData.branch_name && `(${branchData.branch_name})`}
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}
