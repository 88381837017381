import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { DataTableWithoutButtons } from "components/DataTable/DataTableWithoutButtons";
import { AfDataType } from "utils/TableColumnData";
import handleWheel from "utils/handleOnWheel";
import { ListCommanWay, UpdateCommanWay } from "API/commonApi";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { Img } from "components";
import moment from "moment";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";
import Select from "react-select";
import { object } from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import { roles } from "utils/constant";

const AttendanceReport: React.FC<any> = (props) => {
  const ADT = AfDataType;
  const [tableData, setTableData] = useState<any>([]);

  const [selectedAgent, setSelectedAgent] = useState({ id: undefined, name: "--- Select All ---" });
  const [allAgents, setAllAgents] = useState([]);

  const [startDate, setStartDate] = useState<any>(moment().startOf("day").startOf("month").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState<any>(moment().endOf("day").endOf("month").format("YYYY-MM-DD"));
  const [query, setQuery] = useState<any>({
    createdAt: {
      $gte: moment().startOf("day").startOf("month"),
      $lte: moment().endOf("day"),
    },
    user: selectedAgent.id,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);

  const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
  const [userDetails, setUserDetails] = useState<any>(userD?.state?.user || {});

  const [userReport, setUserReport] = useState<any>([]);

  useEffect(() => {
    const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
    if (Object.keys(userD).length) {
      setUserDetails(userD.state.user);
      if (userD.state.user.roleName == roles.SUPER_ADMIN) {
        mutateListAgents({ subbranch: undefined });
      } else if (userD.state.user.roleName == roles.SUBBRANCH_ADMIN) {
        mutateListAgents({ subbranch: userD.state.user?.subbranch });
      } else {
        mutateListAgents({ subbranch: undefined });
      }
    }
  }, []);

  const { mutate: mutateListAgents }: any = useMutation(
    (qry: any) => ListCommanWay("/admin/user/list", { subbranch: qry?.subbranch }, { paginate: 100000 }),
    {
      onSuccess: (data: any) => {
        if (data.status == "SUCCESS") {
          setAllAgents([{ id: undefined, name: "--- Select All ---" }, ...data.data.data]);
        }
      },
      onError: (data: any) => {},
    }
  );

  const { isFetching, isLoading, refetch } = useQuery(
    ["attendance_report"],
    () =>
      ListCommanWay(
        "/admin/attendence/list",
        {
          ...query,
        },
        { paginate: 100000, include: ["_user"], order: [["createdAt", "asc"]] }
      ),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          console.log("data.data", data.data);
          let eventData = [];
          data.data?.data.forEach((e) => {
            eventData.push({
              start: moment(e.createdAt, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD"),
              end: moment(e.createdAt, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD"),
              title: e._user.name + " - " + e.type + " - " + moment(e.createdAt, "DD-MM-YYYY HH:mm:ss").format("HH:mm"),
            });
          });
          setTableData(eventData);

          if (selectedAgent.id) {
            let array = data.data.data;
            const groupedData: any[] = array.reduce((acc, entry) => {
              const date = entry.createdAt.split(" ")[0];
              if (!acc[date]) {
                acc[date] = [];
              }
              acc[date].push(entry);
              return acc;
            }, {});

            console.log(groupedData);

            let dateS = moment(startDate).startOf("day");
            let dateE = moment(endDate).endOf("day");
            let totalHolidays = 0;
            let totalDaysInRange = dateE.diff(dateS, "days") + 1  ;
            for (var m = moment(dateS); m.diff(dateE, "days") <= 0; m.add(1, "days")) {
              if (m.day() == 0) {
                totalHolidays++;
              }
            }

            const hoursWorkedPerDay = {};
            let totalHoursInDates = 0;
            let holidayPresent = 0;
            for (const [date, entries] of Object.entries(groupedData)) {
              let totalHours = 0;
              let punchInTime = null;

              if (moment(date, 'DD-MM-YYYY').day() == 0) {
                holidayPresent++;
              }
              entries.forEach((entry) => {
                if (entry.type === "IN") {
                  punchInTime = moment(entry.createdAt, "DD-MM-YYYY HH:mm:ss");
                } else if (entry.type === "OUT" && punchInTime) {
                  const punchOutTime = moment(entry.createdAt, "DD-MM-YYYY HH:mm:ss");
                  totalHours += punchOutTime.diff(punchInTime, "hours", true); // Get difference in hours
                  punchInTime = null; // Reset punchInTime
                }
              });

              hoursWorkedPerDay[date] = totalHours;
              totalHoursInDates += totalHours;
            }

            setUserReport({
              dailyHours: hoursWorkedPerDay,
              totalDays: Object.entries(groupedData).length,
              totalHours: totalHoursInDates,
              holidays: totalHolidays,
              totalDaysInRange: totalDaysInRange,
              holidayPresent: holidayPresent
            });
            console.log(hoursWorkedPerDay);
          }
        } else {
          setTableData([]);
        }
      },
      onError: (data) => {
        setTableData([]);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [query]);

  const __handleSubmit = async () => {
    setQuery({
      createdAt: {
        $gte: moment(startDate).startOf("day"),
        $lte: moment(endDate).endOf("day"),
      },
      user: selectedAgent.id,
    });
  };

  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold ">Attendance Report</h1>
      <div className="px-4  border-[1px] p-4 m-4 rounded-md">
        <div className="flex gap-x-4 gap-y-2 items-center">
          <div className="">
            <label className="block mb-0.5 text-sm font-medium text-gray-900">From Date</label>
            <input
              type="date"
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder="Start Date"
              name="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="">
            <label className="block mb-0.5 text-sm font-medium text-gray-900">To Date</label>
            <input
              type="date"
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder="End Date"
              name="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

          <div>
            <label className="block mb-0.5 text-sm font-medium text-gray-900 dark:text-white">Select Employee</label>

            <Select
              className={`bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full `}
              options={allAgents}
              onChange={(e: any) => {
                setSelectedAgent(e);
              }}
              value={selectedAgent}
              getOptionValue={(e) => {
                return e.id + "";
              }}
              getOptionLabel={(e) => {
                return e.name;
              }}
            />
          </div>
          <button
            type="button"
            onClick={__handleSubmit}
            className=" h-auto rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Submit
          </button>
        </div>
      </div>

      <div className="flex justify-center mb-5">
        {selectedAgent.id && (
          <table className="border w-auto">
            <thead>
              <tr>
                <th className="border px-4">Total Days</th>
                <th className="border px-4">Holiday</th>
                <th className="border px-4">Present Days</th>
                <th className="border px-4">Holiday Present</th>
                <th className="border px-4">Total Hours</th>
                <th className="border px-4">Avg Daily Hours</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-4">{userReport.totalDaysInRange}</td>
                <td className="border px-4">{userReport.holidays}</td>
                <td className="border px-4">{userReport.totalDays}</td>
                <td className="border px-4">{userReport.holidayPresent}</td>
                <td className="border px-4">{userReport.totalHours?.toFixed(2)} Hours</td>
                <td className="border px-4">{(userReport.totalHours / userReport.totalDays)?.toFixed(2)} Hours</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <div className="px-4">
        <FullCalendar
          plugins={[dayGridPlugin, listPlugin]}
          events={tableData}
          headerToolbar={{
            center: "title",
            start: "dayGridMonth,dayGridWeek,dayGridDay",
          }}
          eventContent={(eventInfo) => {
            return (
              <>
                <b>{eventInfo.timeText}</b>
                <p>
                  <small>{eventInfo.event.title}</small>
                </p>
              </>
            );
          }}
        />
      </div>
    </>
  );
};

export default AttendanceReport;
