export const constantValues = {
  defaultFields: [
    "isDeleted",
    "isActive",
    "createdAt",
    "updatedAt",
    "addedBy",
    "updatedBy",
  ],
};

export const roles = {
  SUPER_ADMIN: "Admin",
  BRANCH_ADMIN: "Branch",
  SUBBRANCH_ADMIN: "SubBranch",
  AGENT: "DELIVERY",
  ACCOUNTANT: "Accountant",
  HR_CS: "HR & CUSTOMER SERVICE",
  CS_DELIVERY: "CUSTOMER SERVICE & DELIVERY",
  CUSTOMER_SERVICE: "CUSTOMER SERVICE",
  MANAGER: "Manager"
};
 